<template>
    <BT-Dialog
        :buttonClass="buttonClass"
        :cardClass="cardClass"
        cardTextClass="ma-0 pa-0"
        flat
        :fullscreen="fullscreen"
        :hideToggle="hideToggle"
        :icon="icon"
        @open="x => { onOpen() }"
        :label="label"
        :loading="isLoading"
        @ok="saveAssignment(false)"
        :small="small"
        :text="text"
        width="700">
        <template v-slot:header>
            <v-card-title>
                Assign And Pick Stock
                <v-btn small @click="reset" class="primary ml-4">Reset</v-btn>
                <v-btn small @click="refresh" class="primary ml-4">Refresh All</v-btn>
            </v-card-title>
        </template>
        <!-- <template v-slot:actions>
            <v-btn text color="primary" @click="saveAssignment(true)">Pick</v-btn>
        </template> -->
            <template>
                <BT-Blades
                    :bladeHeight="fullscreen ? '85vh' : '100%'"
                    :maximizedCount="6"
                    :anchorBlades="[
                        { bladeName: 'consignment-order-items-component', isMinimized: false },
                        { bladeName: 'consignment-entry-items-component', isMinimized: false },
                        { bladeName: 'consignment-entry-item-suggestions-component', isMinimized: false }]">
                    <template v-slot="bladesData">
                        <BT-Blade-List
                            :bladesData="bladesData"
                            bladeName="consignment-order-items-component"
                            :canClose="false"
                            :canRefresh="false"
                            @change="orderItem => { currentOrderItem = orderItem }"
                            :items="filteredOrderItems"
                            @mounted="refreshOnOpen"
                            :refreshToggle="refreshToggle"
                            title="Ordered Items">
                            <template slot-scope="{ item }">
                                <v-list-item-avatar>
                                    <v-slide-x-transition>
                                        <v-icon v-if="item.totalAssigned == item.quantity && item.totalAssigned == item.totalPicked" class="success--text">mdi-check-all</v-icon>
                                        <v-icon v-else-if="item.totalAssigned == item.quantity" class="success--text">mdi-check</v-icon>
                                        <v-icon v-else-if="item.totalAssigned > 0" class="warning--text">mdi-check</v-icon>
                                        <v-icon v-else class="error--text">mdi-close</v-icon>
                                    </v-slide-x-transition>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>{{ item.product.productName }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ item.product.abbreviation }}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-list-item-action-text v-if="item.totalAssigned > item.quantity">
                                        <span class="lighten-4">+{{ item.totalAssigned - item.quantity | toDisplayNumber }}</span>
                                    </v-list-item-action-text>
                                    {{ item.quantity | toDisplayNumber }}
                                </v-list-item-action>
                            </template>
                        </BT-Blade-List>
                        <BT-Blade-List
                            :bladesData="bladesData"
                            bladeName="consignment-entry-items-component"
                            :canClose="false"
                            :canRefresh="false"
                            :items="filteredEntryItems"
                            @mounted="refreshOnOpen"
                            :refreshToggle="refreshToggle"
                            three-line
                            title="Stock">
                            <template v-slot:top>
                                <v-btn small class="primary" width="113" @click="pickAll">Pick All</v-btn>
                                <v-btn small class="primary" width="113" @click="assignAll" :disabled="!stockLoaded">Assign All</v-btn>
                                <v-btn small class="primary" width="113" @click="refreshStockEntries" :disabled="isLengthyArray(stockEntries)">Show All</v-btn>
                            </template>
                            <template slot-scope="{ item }">
                                <v-list-item-content>
                                    <v-list-item-title>{{ currentOrderItem == null ? item.product.productName : (item.batch ? item.batch.batchcode : 'No Batch') }}</v-list-item-title>
                                    <v-list-item-subtitle v-if="item.batch != null">DOM: {{ item.batch.dom | toShortDate }} | EXP: {{ item.batch.exp | toShortDate }}</v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                        <v-row dense>
                                            <v-col cols="4">
                                                <v-edit-dialog>
                                                    <span :class="item.quantityPicked == item.quantity ? (item.quantityPicked == 0 ? '' : 'success--text') : 'error--text'">Pick: {{ item.quantityPicked | toDisplayNumber }}</span>
                                                    <template v-slot:input>
                                                        <BT-Number-Edit
                                                            v-model="item.quantityPicked"
                                                            singleLine
                                                            @input="refreshConsignmentEntryItem(item)" />
                                                    </template>
                                                </v-edit-dialog>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-edit-dialog>
                                                    <span>Assign: {{ item.quantity | toDisplayNumber }}</span>
                                                    <template v-slot:input>
                                                        <BT-Number-Edit
                                                            v-model="item.quantity"
                                                            singleLine
                                                            @input="refreshConsignmentEntryItem(item)" />
                                                    </template>
                                                </v-edit-dialog>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-slide-x-transition>
                                                    <span v-if="stockLoaded" :class="item.available < 0 ? 'error--text' : ''">Free: {{ item.available || 'Unknown' | toDisplayNumber }}</span>
                                                </v-slide-x-transition>
                                            </v-col>
                                        </v-row>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                
                                <!-- <v-list-item-action>
                                    <v-edit-dialog>
                                        <v-list-item-subtitle>{{ item.quantity | toDisplayNumber }}</v-list-item-subtitle>
                                        <template v-slot:input>
                                            <BT-Number-Edit
                                                v-model="item.quantity"
                                                singleLine />
                                        </template>
                                    </v-edit-dialog>
                                </v-list-item-action> -->
                            </template>
                        </BT-Blade-List>
                        <BT-Blade-List
                            :bladesData="bladesData"
                            bladeName="consignment-entry-item-suggestions-component"
                            :canClose="false"
                            :canRefresh="false"
                            flexColumn
                            flexWidth="auto"
                            :items="stockSuggestions"
                            title="Suggestions">
                            <template v-slot:top>
                                <BT-Blade-Button
                                    v-if="isLengthyArray(stockSuggestions)"
                                    buttonClass="success my-1"
                                    @click="useAllSuggestions"
                                    icon="mdi-check"
                                    label="Use All" />
                                <BT-Blade-Button
                                    v-if="!isLengthyArray(stockSuggestions) && !suggestionsLoaded"
                                    buttonClass="success my-1"
                                    @click="refreshSuggestions"
                                    icon="mdi-magnify"
                                    label="Find Suggestions" />
                            </template>
                            <template slot-scope="{ item }">
                                <v-list-item-icon>
                                    <v-icon 
                                        class="success--text"
                                        @click="useSuggestion(item)"
                                        title="Use"
                                        small>
                                        mdi-check
                                    </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <BT-Entity
                                            navigation="products"
                                            itemText="productName"
                                            :itemValue="item.productID" />
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        <BT-Entity
                                            navigation="batches"
                                            itemText="batchcode"
                                            :itemValue="item.batchID" />
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                        DOM: 
                                        <BT-Entity 
                                            navigation="batches" 
                                            inline 
                                            itemText="dom" 
                                            textFilter="toShortDate" 
                                            :itemValue="item.batchID" /> | EXP: 
                                        <BT-Entity 
                                            navigation="batches" 
                                            inline 
                                            itemText="exp" 
                                            textFilter="toShortDate" 
                                            :itemValue="item.batchID" />
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>{{ item.quantity | toDisplayNumber }}</v-list-item-action>
                            </template>
                        </BT-Blade-List>
                        <BT-Snack v-model="msg" />
                    </template>
                </BT-Blades>
            </template>
    </BT-Dialog>
</template>

<script>
import { firstBy } from 'thenby';

export default {
    name: 'Stock-Assign-Dialog',
    components: {
        BTDialog: () => import('~components/BT-Dialog.vue'),
    },
    data: function() {
        return {
            consignmentEntryItems: [],
            consignmentOrderItems: [],
            currentOrderItem: null,
            hideToggle: false,
            isLoading: false,
            isSourceChanged: true,
            msg: null,
            refreshToggle: false,
            stockConsignment: null,
            stockEntries: [],
            stockLoaded: false,
            stockSuggestions: [],
            suggestionsLoaded: false,
            suggestionsUsed: [],
        }
    },
    props: {
        buttonClass: {
            type: String,
            default: 'primary--text flat'
        },
        cardClass: {
            type: String,
            default: null
        },
        consignment: null,
        fullscreen: {
            type: Boolean,
            default: true
        },
        icon: {
            type: String,
            default: 'mdi-pencil'
        },
        label: {
            type: String,
            default: 'Stock Assign And Pick'
        },
        small: {
            type: Boolean,
            default: true
        },
        suggestionsFirst: {
            type: Boolean,
            default: false
        },
        text: {
            type: String,
            default: 'Assign'
        },
        value: null
    },
    watch: {
        consignment: function()  {
            this.isSourceChanged = true;
        }
    },
    computed: {
        filteredEntryItems() {
            var l = this.currentOrderItem != null ? this.consignmentEntryItems.filter(x => x.productID == this.currentOrderItem.productID) : this.consignmentEntryItems;
            return l.sort(firstBy(x => x.product.sortNumber).thenBy(x => x.batch.exp));
        },
        filteredOrderItems() {
            return this.stockConsignment != null ? this.consignmentOrderItems : []
        },
        filteredStockEntries() {
            var l = [];
            if (this.currentOrderItem != null) {
                l = this.stockEntries.filter(y => this.currentOrderItem.productID == y.productID);
            }
            else {
                l = this.stockEntries;
            }
            return l.sort(firstBy(x => x.product.sortNumber).thenBy(x => x.batch.exp));
        },
        locationID() {
            return this.stockConsignment != null ? this.stockConsignment.departureLocationID : null;
        }
    },
    methods: {
        assignAll() {
            var p1 = this.consignmentOrderItems.map(x => x.productID);
            var p2 = this.consignmentEntryItems.map(x => x.productID);
            var allProdIDs = [...new Set([...p1, ...p2])];

            allProdIDs.forEach(prodID => {
                var consignmentEntryItems = this.consignmentEntryItems.filter(x => x.productID == prodID);
                var orderItem = this.consignmentOrderItems.find(x => x.productID == prodID);
                if (orderItem != null) {
                    var remainingNeeds = orderItem.quantity - consignmentEntryItems.sum(x => x.quantity);
                    if (remainingNeeds > 0) {
                        consignmentEntryItems.forEach(x => {
                            if (remainingNeeds > 0 && x.available > 0) {
                                if (remainingNeeds >= x.available) {
                                    x.quantity += x.available;
                                    remainingNeeds -= x.available;
                                    x.available = 0;
                                }
                                else {
                                    x.quantity += remainingNeeds;
                                    x.available -= remainingNeeds;
                                    remainingNeeds = 0;
                                }
                            }
                        })
                    }
                }
                else {
                    //no order item found, but make sure entry items are 0;
                    consignmentEntryItems.forEach(x => {
                        x.available += x.quantity;
                        x.quantityPicked = 0;
                        x.quantity = 0;
                    })
                }
            });

            this.refreshConsignmentOrderItems();
        },
        async onOpen() {
            if (this.consignment == null || this.consignment.departureLocationID == null) {
                this.hideToggle = !this.hideToggle;
                return;
            }

            if (this.isSourceChanged) {
                //reset
                this.currentOrderItem = null;
                this.stockConsignment = this.consignment;
                this.consignmentEntryItems = [];
                this.consignmentOrderItems = [];
                this.stockEntries = [];
                this.stockLoaded = false;
                this.stockSuggestions = [];
                this.suggestionsLoaded = false;
                this.suggestionsUsed = [];

                //this.consignmentEntryItems = this.copyDeep(this.stockConsignment.consignmentEntryItems);
                this.refreshConsignmentEntryItems();
                this.consignmentOrderItems = this.copyDeep(this.stockConsignment.consignmentOrderItems).map(x => Object.assign({}, x, { totalAssigned: 0, totalPicked: 0 }));
                this.refreshConsignmentOrderItems();
                
                if (this.suggestionsFirst && !this.isLengthyArray(this.stockSuggestions)) {
                    await this.refreshSuggestions();
                }
                this.isSourceChanged = false;
            }
        },
        pickAll() {
            this.consignmentEntryItems.forEach(x => {
                if (x.quantity > 0) {
                    x.quantityPicked = x.quantity;
                }
            })
            this.refreshConsignmentOrderItems();
        },
        quantityAssigned(productID) { //true means order has been fully provisioned
            return this.consignmentEntryItems.filter(y => y.productID == productID).sum(x => x.quantity);
        },
        quantity(item) {
            return this.stockEntries.filter(y => y.productID == item.productID && y.batchID === item.batchID).sum('quantity');
        },
        quantitySuggested(productID, batchID) {
            var s = this.suggestionsUsed.find(x => x.productID == productID && x.batchID == batchID);
            return s ? s.quantity : 0;
        },
        refreshOnOpen() {
            this.refreshToggle = !this.refreshToggle;
        },
        refreshConsignmentEntryItem(item) {
            item.available = item.anchorAvailable - item.quantity;
            this.refreshConsignmentOrderItem(item.productID);
        },
        refreshConsignmentEntryItems() {
            this.consignmentEntryItems = [
                ...this.stockConsignment.consignmentEntryItems.map(x => {
                    var quantitySuggested = this.quantitySuggested(x.productID, x.batchID);
                    var stockEntry = this.stockEntries.find(entry => entry.productID == x.productID && entry.batchID == x.batchID);
                    if (stockEntry != null) {
                        return Object.assign({}, x, { anchorAvailable: stockEntry.available, available: stockEntry.available - quantitySuggested, quantity: x.quantity + quantitySuggested });
                    }
                    else {
                        return Object.assign({}, x, { anchorAvailable: 0, available: 0, quantity: x.quantity + quantitySuggested });
                    }}), 
                ...this.stockEntries.filter(x => !this.stockConsignment.consignmentEntryItems.some(y => y.productID == x.productID && y.batchID == x.batchID)).map(a => {
                    var quantitySuggested = this.quantitySuggested(a.productID, a.batchID);
                    return {
                        id: null,
                        productID: a.productID,
                        product: a.product,
                        batchID: a.batchID,
                        batch: a.batch,
                        quantity: quantitySuggested,
                        quantityPicked: 0,
                        anchorAvailable: a.available,
                        available: a.available - quantitySuggested,
                    }
                })];
        },
        refreshConsignmentOrderItem(productID) {
            this.consignmentOrderItems.forEach(x => {
                if (x.productID == productID) {
                    var cItems = this.consignmentEntryItems.filter(y => y.productID == x.productID);
                    x.totalPicked = cItems.sum(x => x.quantityPicked);
                    x.totalAssigned = cItems.sum(x => x.quantity);
                }
            })
        },
        refreshConsignmentOrderItems() {
            this.consignmentOrderItems.forEach(x => {
                var cItems = this.consignmentEntryItems.filter(y => y.productID == x.productID);
                x.totalPicked = cItems.sum(x => x.quantityPicked);
                x.totalAssigned = cItems.sum(x => x.quantity);
            })
        },
        async refreshStockEntries(refresh = false) {
            try {
                this.isLoading = true;
                this.$forceUpdate();

                var stockEntriesRes = await this.$BlitzIt.store.getAll('stock-items', { locationID: this.locationID }, refresh, null);
                this.stockEntries = stockEntriesRes.map(x => this.copyDeep(x)); //.map(x => Object.assign({}, x, { id: null, anchorAvailable: x.available, quantityAvailable: x.available, quantity: 0, quantityPicked: 0 }));
                this.refreshConsignmentEntryItems();
                this.refreshConsignmentOrderItems();
                this.stockLoaded = true;
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.isLoading = false;
            }
        },
        async refreshSuggestions(refresh = false) {
            try {
                this.isLoading = true;
                this.$forceUpdate();

                this.stockSuggestions = await this.$BlitzIt.store.getAll('stock-entry-item-suggestions', { id: this.stockConsignment.id, provisionStrategy: null }, refresh, null);
                this.suggestionsLoaded = true;
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.isLoading = false;
                this.$forceUpdate();
            }
        },
        async refresh() {
            this.reset();
            await this.$BlitzIt.store.getAll('products', null, true, null);
            await this.$BlitzIt.store.getAll('batches', null, true, null);
            this.refreshSuggestions();
            this.refreshStockEntries();
            
        },
        async reset() {
            this.isSourceChanged = true;
            await this.onOpen();
        },
        saveAssignment(doPick) {
            if (doPick) {
                this.consignmentEntryItems.forEach(x => {
                    x.quantityPicked = x.quantity;
                })
            }

            this.stockConsignment.consignmentEntryItems = this.consignmentEntryItems.filter(x => x.quantity != 0);

            if (doPick) {
                this.hideToggle = !this.hideToggle;
            }

            this.$emit('ok', this.stockConsignment);
        },
        // unassign(entryItem) {
        //     entryItem.quantity = 0;
        //     entryItem.quantityPicked = 0;
        //     this.updateEntryItem(entryItem);
        // },
        // updateEntryItem(entryItem) {
        //     //var orderItem = this.orderItems.find(y => y.productID == entryItem.productID);
        //     var a = this.stockEntries.filter(y => y.productID == entryItem.productID).sum('quantity');
        //     entryItem.quantityAvailable = entryItem.anchorAvailable - a;
        // },
        async useAllSuggestions() {
            try {
                this.isLoading = true;
                this.$forceUpdate();

                var products = await this.$BlitzIt.store.getAll('products');
                var batches = await this.$BlitzIt.store.getAll('batches');
                this.stockSuggestions.forEach(x => {
                    var existingEntry = this.consignmentEntryItems.find(y => y.batchID == x.batchID && y.productID == x.productID);
                    if (existingEntry != null) {
                        existingEntry.quantity += x.quantity;
                        existingEntry.available -= x.quantity;
                        existingEntry.anchorAvailable -= x.quantity;
                    }
                    else {
                        this.consignmentEntryItems.push({
                            id: null,
                            productID: x.productID,
                            product: products.find(p => p.id == x.productID),
                            batchID: x.batchID,
                            batch: batches.find(p => p.id == x.batchID),
                            quantity: x.quantity,
                            quantityPicked: 0,
                            anchorAvailable: 0 - x.quantity,
                            available: 0 - x.quantity
                        })
                    }
                })
                this.suggestionsUsed = this.copyDeep(this.stockSuggestions);
                this.stockSuggestions = [];

                this.refreshConsignmentOrderItems();
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.isLoading = false;
            }
            
        },
        async useSuggestion(suggestedItem) {
            try {
                this.isLoading = true;
                this.$forceUpdate();

                var x = suggestedItem;
                var products = await this.$BlitzIt.store.getAll('products');
                var batches = await this.$BlitzIt.store.getAll('batches');
                var existingEntry = this.consignmentEntryItems.find(y => y.batchID == x.batchID && y.productID == x.productID);
                if (existingEntry != null) {
                    existingEntry.quantity += x.quantity;
                    existingEntry.available -= x.quantity;
                    existingEntry.anchorAvailable -= x.quantity;
                }
                else {
                    this.consignmentEntryItems.push({
                        id: null,
                        productID: x.productID,
                        product: products.find(p => p.id == x.productID),
                        batchID: x.batchID,
                        batch: batches.find(p => p.id == x.batchID),
                        quantity: x.quantity,
                        quantityPicked: 0,
                        anchorAvailable: 0 - x.quantity,
                        available: 0 - x.quantity,
                    })
                }

                this.suggestionsUsed.push(this.stockSuggestions[this.stockSuggestions.findIndex(y => y === x)]);
                this.stockSuggestions.splice(this.stockSuggestions.findIndex(y => y === x), 1);

                this.refreshConsignmentOrderItems();
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.isLoading = false;
            }
            
        }
    }
}
</script>